import React, { useState, useEffect } from "react"
import Helmet from "react-helmet"
import Layout from "../helper/layout"
import styled, { keyframes } from "styled-components"
import NavigationContainer from "../shared/Navigation/NavigationDrupal"
import LogoTop from "../shared/LogoTop/LogoTop"
import Btn from "../shared/Button/Button"
import logo from "../white-co-images/whiteandco-logo-white.svg"
import color from "../style/color"
import Footer from "../shared/Footer/FooterDrupal"
import curve from "../white-co-images/line.svg"

const smile = keyframes`
from { opacity: 1; stroke-dashoffset: 778; } to { opacity: 1; stroke-dashoffset: 0; }
`

const StyledLogo = styled.svg`
  width: 100%;
  max-width: 441px;
  margin: -42px auto 50px;
  backface-visibility: hidden;

  @media (max-width: 1023px) {
    margin-bottom: 35px;
  }
    & path {
      opacity: 0;
    }
   .line {
    opacity: 1;
    transform-origin:	50% 0;
    transform:scale(-1, 1);
    stroke-dasharray: 778;
    stroke-dashoffset: 778;
    animation: ${smile} 1.6s ease-out forwards;
    }
    @media (max-width: 1023px) {
      margin-bottom: 35px;
    }
  }
`
const line = (
  <StyledLogo viewBox="0 0 495 249" preserveAspectRatio="xMinYMin meet">
    <path
      className="line"
      fill="none"
      stroke="#FFF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M493.4 0 L493.4 1.5 Q493.4 103.45 421.3 175.4 349.35 247.45 247.4 247.45 145.45 247.45 73.4 175.4 1.4 103.45 1.4 1.5 L1.4 0"
    />
  </StyledLogo>
)

const NotFound = () => {
  const [menuIsOpen, toggleMenu] = useState(false)

  return (
    <Layout>
      <Helmet>
        <meta charSet="utf-8" />
        <title>White & Co. | Page Not Found</title>
        <meta name="description" content={"Page not found"} />
        {process.env.GATSBY_AWS_BRANCH !== "master" && (
          <meta name="robots" content="noindex, nofollow" />
        )}
        <link rel="canonical" href="https://www.whiteandcodental.co.uk/404" />
      </Helmet>
      <LogoTop />
      <NavigationContainer
        slug={"/404"}
        toggleMenu={toggleMenu}
        menuIsOpen={menuIsOpen}
      />
      <ComponentContainer hideContent={menuIsOpen}>
        <Container>
          <img src={logo} alt="White & Co logo" />
          {line}
          <h3>Oops! The page you were looking for doesn't exist.</h3>
          <p>
            The page requested couldn't be found: this could be a spelling error
            or a removed page.
          </p>
          <Btn href={process.env.BASE} text="Go to homepage" tertiary />
        </Container>
        <Footer />
      </ComponentContainer>
    </Layout>
  )
}

const Container = styled.div`
  background: ${color.brand.blue};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  border-top: 1px solid white;
  padding: 13.6rem 0 10rem;
  position: relative;

  & img:nth-of-type(1) {
    width: 28rem;
  }

  & img:nth-of-type(2) {
    width: 34rem;
    margin-top: -42px;
    margin-bottom: 5rem;
  }

  & h3 {
    font-family: headingFontLight;
    font-weight: 300;
    margin-bottom: 3rem;
    text-align: center;
  }

  & p {
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 3.5rem;
    max-width: 56rem;
    text-align: center;
  }

  &:before {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    background: ${color.brand.blue};
    width: 100%;
    height: 100vh;
    z-index: -1;
  }

  @media (max-width: 1023px) {
    padding: 75px 25px;

    & p {
      font-size: 16px;
      line-height: 26px;
    }
  }
`

const ComponentContainer = styled.div<ComponentContainerProps>`
  ${props => props.hideContent && `visibility: hidden`};
`

export default NotFound
